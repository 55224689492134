import React, { useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { AuthContext } from '../../../common/context/auth-context';
import { useHttpClient } from '../../../common/hooks/http-hook';

const ReplyRequired = (props) => {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const handleClose = () => {
    props.close();
  };

  const updateResponse = async (event) => {
    event.preventDefault();
    const reply = event.currentTarget.value;
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/sms/replyrequired',
        'POST',
        JSON.stringify({
          mid: props.mid,
          replyrequired: reply
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
    } catch (err) {
      console.error(err);
    }
    props.close();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm">
      <DialogTitle>Is a response required for this message?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'right', pb: 2 }}>
        <ButtonGroup variant="contained">
          <Button
            onClick={updateResponse}
            value={false}
            sx={{ bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}>
            No
          </Button>
          <Button
            onClick={updateResponse}
            value
            autoFocus
            sx={{ bgcolor: 'secondary.main', '&:hover': { bgcolor: 'secondary.dark' } }}>
            Yes
          </Button>
          <Button
            onClick={updateResponse}
            value={3}
            sx={{ bgcolor: 'secondary.main', '&:hover': { bgcolor: 'secondary.dark' } }}>
            Mark Unread
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default ReplyRequired;
