import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  mask_all_text: true,
  mask_all_element_attributes: true,
  capture_pageview: false // This is a SPA, so we need to capture the pageview manually
};

Sentry.init({
  dsn: 'https://67b1cacd2d686829074e78d62ced5e05@o4506593884831744.ingest.sentry.io/4506594441887744',
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({ maskAllText: false, blockAllMedia: false })
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  environment: process.env.ENV ? process.env.ENV : 'production'
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
      <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
        <App />
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

serviceWorker.unregister();
