import React, { useEffect, useState, useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { useHttpClient } from '../hooks/http-hook'; // Import your HTTP hook if you have one.
import { AuthContext } from '../context/auth-context'; // Import your AuthContext if you have one.
import LoadingSpinner from './LoadingSpinner';
import ErrorModal from './ErrorModal';

const AddOrderModal = ({ open, onClose, clear }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient(); // Assuming you use a custom hook for HTTP requests
  const [orderDetails, setOrderDetails] = useState({
    kitsRequested: '',
    orderType: 'Lab Test',
    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    quantity: '',
    quantity2: '',
    quantity3: '',
    carrier: '',
    trackingNumber: '',
    shippingStatus: 'Pending',
    theUser: '',
    expeditedShipping: '0', // Default to 'No'
    expeditedShippingCharge: '0',
    specialShippingRate: '',
    notes: '',
    shippingOption: 'Shipbob',
    instantTestQuantity: '', // New field for instant test quantity
    labTestQuantity: '', // New field for lab test quantity
    includeBAC: false, // New field for BAC checkbox
    partnerName: '' // New field for partner name
    // Remove or comment out the following fields:
    // kitsRequested: '',
    // quantity: '',
    // quantity2: '',
    // quantity3: '',
  });

  // Fetch client information when `open` changes and is truthy.
  useEffect(() => {
    const fetchClientInfo = async () => {
      if (!open) return; // Do nothing if `open` is falsy.
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/accountable/user`,
          'POST',
          JSON.stringify({ id: open }),
          { 'Content-Type': 'application/json', Authorization: `Bearer ${auth.token}` }
        );
        if (responseData) {
          setOrderDetails((prevState) => ({
            ...prevState,
            shippingAddressLine1: responseData.shippingAddressLine1,
            shippingAddressLine2: responseData.shippingAddressLine2,
            shippingCity: responseData.shippingCity,
            shippingState: responseData.shippingState,
            shippingZip: responseData.shippingZip,
            theUser: responseData._id
          }));
        }
      } catch (error) {
        console.error('Failed to fetch client information:', error);
      }
    };

    fetchClientInfo();
  }, [open, sendRequest, auth.token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails((prevState) => {
      const newState = { ...prevState };

      // Handle expedited shipping logic
      if (name === 'expeditedShipping' && value === '1') {
        // If selecting free expedited shipping, disable paid expedited
        newState.expeditedShipping = '1';
        newState.expeditedShippingCharge = '0';
        newState.specialShippingRate = '';
      } else if (name === 'expeditedShippingCharge' && value === '1') {
        // If selecting paid expedited, disable free expedited
        newState.expeditedShipping = '0';
        newState.expeditedShippingCharge = '1';
        newState.specialShippingRate = newState.specialShippingRate || '35';
      } else if (name === 'specialShippingRate') {
        // Only allow special shipping rate when paid expedited is selected
        if (prevState.expeditedShippingCharge === '1') {
          newState[name] = value;
        }
      } else {
        newState[name] = value;
      }

      return newState;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/submitOrder`,
        'POST',
        JSON.stringify(orderDetails),
        { 'Content-Type': 'application/json', Authorization: `Bearer ${auth.token}` }
      );
      setOrderDetails({
        kitsRequested: '',
        orderType: 'Lab Test',
        shippingAddressLine1: '',
        shippingAddressLine2: '',
        shippingCity: '',
        shippingState: '',
        shippingZip: '',
        quantity: '',
        quantity2: '',
        carrier: '',
        trackingNumber: '',
        shippingStatus: 'Pending',
        theUser: '',
        expeditedShipping: '0',
        expeditedShippingCharge: '0', // Default to 'No'
        specialShippingRate: '',
        notes: '',
        shippingOption: 'Shipbob',
        instantTestQuantity: '',
        labTestQuantity: '',
        includeBAC: false,
        partnerName: ''
      });
      clear();
    } catch (error) {
      console.error('Failed to fetch client information:', error);
    }
    console.log(orderDetails);
    // Submit logic here
  };

  const isReceivedFromPartner = orderDetails.shippingOption === 'Received from Partner';

  const shippingCharge =
    orderDetails.specialShippingRate || (orderDetails.expeditedShippingCharge === '1' ? '35' : '0');

  // Add this validation function
  const isFormValid = () => {
    if (isReceivedFromPartner) return true;

    const hasQuantity =
      orderDetails.instantTestQuantity > 0 ||
      orderDetails.labTestQuantity > 0 ||
      orderDetails.includeBAC;

    const hasAddress =
      orderDetails.shippingAddressLine1 &&
      orderDetails.shippingCity &&
      orderDetails.shippingState &&
      orderDetails.shippingZip;

    return hasQuantity && hasAddress;
  };

  return (
    <>
      {isLoading && <LoadingSpinner open={isLoading} />}
      {error && (
        <ErrorModal open={!!error} title="Error Ordering" alert={error} clearError={clearError} />
      )}
      <Dialog
        open={!!open} // Ensure dialog opens only if `open` is truthy
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth>
        <DialogTitle id="form-dialog-title">Add New Order</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Shipping Option"
                  variant="outlined"
                  select
                  name="shippingOption"
                  value={orderDetails.shippingOption}
                  onChange={handleInputChange}>
                  <MenuItem value="Shipbob">Shipbob</MenuItem>
                  <MenuItem value="Local Office">Local Office</MenuItem>
                  <MenuItem value="Received from Partner">
                    Received from Partner (Don&apos;t use yet)
                  </MenuItem>
                </TextField>
              </Grid>

              {isReceivedFromPartner && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Partner Name"
                    variant="outlined"
                    select
                    name="partnerName"
                    value={orderDetails.partnerName}
                    onChange={handleInputChange}>
                    <MenuItem value="Partner1">Partner 1</MenuItem>
                    <MenuItem value="Partner2">Partner 2</MenuItem>
                    {/* Add more partner options as needed */}
                  </TextField>
                </Grid>
              )}

              {!isReceivedFromPartner ? (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Instant Test Quantity"
                      fullWidth
                      variant="outlined"
                      type="number"
                      name="instantTestQuantity"
                      value={orderDetails.instantTestQuantity}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Lab Test Quantity"
                      fullWidth
                      variant="outlined"
                      type="number"
                      name="labTestQuantity"
                      value={orderDetails.labTestQuantity}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} container alignItems="center" justifyContent="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={orderDetails.includeBAC}
                          onChange={(e) =>
                            handleInputChange({
                              target: { name: 'includeBAC', value: e.target.checked }
                            })
                          }
                          name="includeBAC"
                          color="primary"
                        />
                      }
                      label="Include BAC"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={orderDetails.includeBAC}
                        onChange={(e) =>
                          handleInputChange({
                            target: { name: 'includeBAC', value: e.target.checked }
                          })
                        }
                        name="includeBAC"
                        color="primary"
                      />
                    }
                    label="Include BAC"
                  />
                </Grid>
              )}

              {!isReceivedFromPartner && (
                <>
                  {/* Address fields */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Shipping Address Line 1"
                      fullWidth
                      variant="outlined"
                      name="shippingAddressLine1"
                      value={orderDetails.shippingAddressLine1}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Shipping Address Line 2"
                      fullWidth
                      variant="outlined"
                      name="shippingAddressLine2"
                      value={orderDetails.shippingAddressLine2}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="City"
                      fullWidth
                      variant="outlined"
                      name="shippingCity"
                      value={orderDetails.shippingCity}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="State"
                      fullWidth
                      variant="outlined"
                      name="shippingState"
                      value={orderDetails.shippingState}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Zip Code"
                      fullWidth
                      variant="outlined"
                      name="shippingZip"
                      value={orderDetails.shippingZip}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <br />
                <TextField
                  label="Notes"
                  fullWidth
                  variant="outlined"
                  name="notes"
                  value={orderDetails.notes}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDetails.expeditedShipping === '1'}
                      onChange={(e) =>
                        handleInputChange({
                          target: { name: 'expeditedShipping', value: e.target.checked ? '1' : '0' }
                        })
                      }
                      name="expeditedShipping"
                      color="primary"
                      disabled={orderDetails.expeditedShippingCharge === '1'}
                    />
                  }
                  label="Expedited Shipping - Initial Order - No Charge"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDetails.expeditedShippingCharge === '1'}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: 'expeditedShippingCharge',
                            value: e.target.checked ? '1' : '0'
                          }
                        })
                      }
                      name="expeditedShippingCharge"
                      color="primary"
                      disabled={orderDetails.expeditedShipping === '1'}
                    />
                  }
                  label="Expedited Shipping - Charge"
                />
                {orderDetails.expeditedShippingCharge === '1' && (
                  <TextField
                    label="Special Shipping Rate"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    variant="outlined"
                    name="specialShippingRate"
                    value={orderDetails.specialShippingRate}
                    onChange={handleInputChange}
                    helperText="Leave empty for default $35 charge"
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  This order will charge ${shippingCharge} for shipping
                </Typography>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={clear} color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={handleSubmit}
                disabled={!isReceivedFromPartner && !isFormValid()}>
                Submit Order
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddOrderModal;
