import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from '@mui/material';
import palette from '../../../theme/palette';

interface Tox {
  videoKey: string;
  Notes: string;
  toxType: number;
  testResult?: 'Positive' | 'Negative';
  reviewNotes?: string;
}

interface VideoTestReviewModalProps {
  open: boolean;
  onClose: () => void;
  tox: Tox | null;
  onApprove: (testResult?: 'Positive' | 'Negative', reviewNotes?: string) => void;
  onReject: (reviewNotes?: string) => void;
}

const VideoTestReviewModal: React.FC<VideoTestReviewModalProps> = ({
  open,
  onClose,
  tox,
  onApprove,
  onReject
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [testResult, setTestResult] = useState<'Positive' | 'Negative' | null>(null);
  const [reviewNotes, setReviewNotes] = useState('');
  const [isReviewed, setIsReviewed] = useState(false);

  // Clear state and check if tox is already reviewed when modal is opened
  useEffect(() => {
    if (open) {
      setTestResult(tox?.testResult || null);
      setReviewNotes(tox?.reviewNotes || '');
      setIsReviewed(Boolean(tox?.testResult || tox?.reviewNotes));
    }
  }, [open, tox]);

  const handleApprove = () => {
    if (tox?.toxType === 2) {
      if (testResult) {
        onApprove(testResult, reviewNotes);
      }
    } else {
      onApprove(undefined, reviewNotes);
    }
  };

  const handleReject = () => {
    onReject(reviewNotes);
  };

  const isActionDisabled =
    isReviewed || (tox?.toxType === 2 && (!testResult || !reviewNotes.trim()));

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: isDesktop ? 900 : 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {isDesktop ? (
          <DesktopLayout
            tox={tox}
            onApprove={handleApprove}
            onReject={handleReject}
            testResult={testResult}
            setTestResult={setTestResult}
            reviewNotes={reviewNotes}
            setReviewNotes={setReviewNotes}
            isActionDisabled={isActionDisabled}
            isReviewed={isReviewed}
          />
        ) : (
          <MobileLayout
            tox={tox}
            onApprove={handleApprove}
            onReject={handleReject}
            testResult={testResult}
            setTestResult={setTestResult}
            reviewNotes={reviewNotes}
            setReviewNotes={setReviewNotes}
            isActionDisabled={isActionDisabled}
            isReviewed={isReviewed}
          />
        )}
      </Box>
    </Modal>
  );
};

interface LayoutProps {
  tox: Tox | null;
  onApprove: () => void;
  onReject: () => void;
  testResult: 'Positive' | 'Negative' | null;
  setTestResult: React.Dispatch<React.SetStateAction<'Positive' | 'Negative' | null>>;
  reviewNotes: string;
  setReviewNotes: React.Dispatch<React.SetStateAction<string>>;
  isActionDisabled: boolean;
  isReviewed: boolean;
}

const DesktopLayout: React.FC<LayoutProps> = ({
  tox,
  onApprove,
  onReject,
  testResult,
  setTestResult,
  reviewNotes,
  setReviewNotes,
  isActionDisabled,
  isReviewed
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, height: '100%' }}>
    <Box sx={{ width: '60%', position: 'relative', flexGrow: 1 }}>
      <video
        controls
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          maxHeight: '100%'
        }}>
        <source src={tox?.videoKey} type="video/mp4" />
      </video>
    </Box>
    <Box sx={{ width: '40%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
        <Typography variant="h6" component="h2">
          Notes:
        </Typography>
        <Typography>
          {tox?.Notes.split('\n').map((note, index) => (
            <span key={index}>
              {note}
              <br />
            </span>
          ))}
        </Typography>
      </Box>
      {tox?.toxType === 2 && (
        <TestResultSelection
          testResult={testResult}
          setTestResult={setTestResult}
          disabled={isReviewed}
        />
      )}
      <ReviewNotesInput
        reviewNotes={reviewNotes}
        setReviewNotes={setReviewNotes}
        disabled={isReviewed}
        required={tox?.toxType === 2}
      />
      <ButtonGroup onApprove={onApprove} onReject={onReject} isDisabled={isActionDisabled} />
    </Box>
  </Box>
);

const MobileLayout: React.FC<LayoutProps> = ({
  tox,
  onApprove,
  onReject,
  testResult,
  setTestResult,
  reviewNotes,
  setReviewNotes,
  isActionDisabled,
  isReviewed
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
      <video
        controls
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}>
        <source src={tox?.videoKey} type="video/mp4" />
      </video>
    </Box>
    <Box sx={{ flexShrink: 0, overflowY: 'auto', maxHeight: '40%' }}>
      <Typography variant="h6" component="h2">
        Notes:
      </Typography>
      <Typography sx={{ mb: 2 }}>
        {tox?.Notes.split('\n').map((note, index) => (
          <span key={index}>
            {note}
            <br />
          </span>
        ))}
      </Typography>
      {tox?.toxType === 2 && (
        <TestResultSelection
          testResult={testResult}
          setTestResult={setTestResult}
          disabled={isReviewed}
        />
      )}
      <ReviewNotesInput
        reviewNotes={reviewNotes}
        setReviewNotes={setReviewNotes}
        disabled={isReviewed}
        required={tox?.toxType === 2}
      />
      <ButtonGroup onApprove={onApprove} onReject={onReject} isDisabled={isActionDisabled} />
    </Box>
  </Box>
);

const TestResultSelection: React.FC<{
  testResult: 'Positive' | 'Negative' | null;
  setTestResult: React.Dispatch<React.SetStateAction<'Positive' | 'Negative' | null>>;
  disabled: boolean;
}> = ({ testResult, setTestResult, disabled }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle1">Instant Test Result:</Typography>
    <RadioGroup
      row
      value={testResult}
      onChange={(e) => setTestResult(e.target.value as 'Positive' | 'Negative')}>
      <FormControlLabel value="Positive" control={<Radio disabled={disabled} />} label="Positive" />
      <FormControlLabel value="Negative" control={<Radio disabled={disabled} />} label="Negative" />
    </RadioGroup>
  </Box>
);

const ReviewNotesInput: React.FC<{
  reviewNotes: string;
  setReviewNotes: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  required: boolean;
}> = ({ reviewNotes, setReviewNotes, disabled, required }) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle1">Review Notes:</Typography>
    <TextField
      multiline
      rows={3}
      fullWidth
      value={reviewNotes}
      onChange={(e) => setReviewNotes(e.target.value)}
      placeholder="Enter additional review notes here..."
      disabled={disabled}
      required={required}
      error={required && !reviewNotes.trim()}
      helperText={required && !reviewNotes.trim() ? 'Review notes are required' : ''}
    />
  </Box>
);

const ButtonGroup: React.FC<{
  onApprove: () => void;
  onReject: () => void;
  isDisabled: boolean;
}> = ({ onApprove, onReject, isDisabled }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
    <Button
      onClick={onReject}
      variant="contained"
      fullWidth
      disabled={isDisabled}
      sx={{ backgroundColor: palette.error.main, color: palette.error.contrastText }}>
      Reject
    </Button>
    <Button onClick={onApprove} fullWidth variant="contained" disabled={isDisabled}>
      Approve
    </Button>
  </Box>
);

export default VideoTestReviewModal;
