import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import { AuthContext } from '../context/auth-context';
import { useHttpClient } from '../hooks/http-hook';
import AddOrderModal from './AddOrderModal';

const OrderListingModal = (props) => {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [addOrderModalOpen, setAddOrderModalOpen] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/order/clientOrder`,
          'POST',
          JSON.stringify({ id: props.open }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        setOrders(responseData.orders);
        setFilteredOrders(responseData.orders);
      } catch (err) {}
    };
    fetchOrders();
  }, [sendRequest, auth.token, addOrderModalOpen, props.open]);

  useEffect(() => {
    const filterOrders = () => {
      const filtered = orders
        .filter((order) => {
          const shipDate = new Date(order.requestedDate);
          return (
            (!startDate || new Date(shipDate.toDateString()) >= new Date(startDate)) &&
            (!endDate || new Date(shipDate.toDateString()) <= new Date(endDate))
          );
        })
        .sort((a, b) => new Date(b.requestedDate) - new Date(a.requestedDate));
      setFilteredOrders(filtered);
      setCurrentPage(1);
    };
    filterOrders();
  }, [startDate, endDate, orders]);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1); // Reset to first page with new page size
  };

  const totalPages = Math.ceil(filteredOrders.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  let displayedOrders = null;
  if (filteredOrders.length > 0) {
    displayedOrders = filteredOrders.slice(startIndex, startIndex + pageSize);
  }

  const getProductString = (order) => {
    const products = [];
    if (order.labTestQuantity > 0) {
      products.push(`${order.labTestQuantity}x Lab Test`);
    }
    if (order.instantTestQuantity > 0) {
      products.push(`${order.instantTestQuantity}x Instant Test`);
    }
    if (order.breathalyzerQuantity > 0) {
      products.push(`${order.breathalyzerQuantity}x Breathalyzer`);
    }
    return products.length > 0 ? products.join(', ') : order.kitsRequested || 'N/A';
  };

  return (
    <>
      {addOrderModalOpen && auth.accountableAdmin && (
        <AddOrderModal
          open={addOrderModalOpen}
          clear={() => setAddOrderModalOpen(false)}
          client={props.open}
        />
      )}
      <Dialog
        open={props.open}
        onClose={() => props.clear()}
        aria-labelledby="order-dialog-title"
        aria-describedby="order-dialog-description"
        fullWidth
        maxWidth="lg">
        <DialogTitle>Order Listing</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {auth.accountableAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setAddOrderModalOpen(props.open)}>
                Add Order
              </Button>
            )}
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={{ margin: '10px' }}
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ margin: '10px' }}
            />
            <TextField
              select
              label="Rows per page"
              value={pageSize}
              onChange={handlePageSizeChange}
              helperText="Select the number of rows to display">
              {[5, 10, 25, 50, 100].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Request Date</TableCell>
                    <TableCell>Shipping Option</TableCell>
                    <TableCell>Products</TableCell>
                    <TableCell>Billed</TableCell>
                    <TableCell>Shipped Date</TableCell>
                    <TableCell>Mailing Address</TableCell>
                    <TableCell>Outbound Status</TableCell>
                    {[...Array(8)].map((_, index) => (
                      <TableCell key={index}>Kit #{index + 1}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedOrders?.map((order) => {
                    const productString = getProductString(order);
                    return (
                      <TableRow key={order.id}>
                        <TableCell>{new Date(order.requestedDate).toDateString()}</TableCell>
                        {/* orderType is deprecated, we include it to handle old orders */}
                        <TableCell>{order.shippingOption ?? order.orderType}</TableCell>
                        <TableCell>{productString}</TableCell>
                        <TableCell>{order.billed ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{new Date(order.fulfilledDate).toDateString()}</TableCell>
                        <TableCell>
                          {order.shippingAddressLine1}
                          <br />
                          {order.shippingAddressLine2}
                          <br />
                          {order.shippingCity}, {order.shippingState}, {order.shippingZip}
                        </TableCell>
                        <TableCell>
                          Tracking:{' '}
                          {order.trackingURL ? (
                            <a href={order.trackingURL} target="_blank" rel="noreferrer">
                              {order.shippingStatus}
                            </a>
                          ) : (
                            order.trackingNumber
                          )}
                        </TableCell>
                        {order.Kits?.map((kit) => (
                          <TableCell key={kit._id}>
                            Kit ID: {kit.kitID} Tracking:{' '}
                            {kit.trackingURL ? (
                              <a href={kit.trackingURL} target="_blank" rel="noreferrer">
                                {kit.shippingStatus}
                              </a>
                            ) : (
                              kit.trackingNumber
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>
            Previous
          </Button>
          <Button
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => setCurrentPage(currentPage + 1)}>
            Next
          </Button>
          <Button onClick={() => props.clear()} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderListingModal;
